subtitle: PhD student at Linköping Universitet (Sweden).
internship: Portfolio of my past projects.
contactme: Don't hesitate to send me an email or message me on Whatsapp/Signal/Telegram.
curriculum: Download my resume in pdf.

navprojects: Projects
navcontact: Contact
navcv: Resume
titleprojects: My Latest Projects
titlecontact: Get in touch
titlecv: Resume

projectlink: "Link"
projectgit: "Git"
projectcollab: "Collaboration"

projects:
  moncraft:
    name: MonCraft
    brief: Voxel game inspired by Minecraft
    desc: Video game made "from sratch" in C++ and OpenGL. Playable in single- or multiplayer mode, on PC or in the browser.
  twwe:
    name: Teeworlds Web Editor
    brief: Map Editor for Teeworlds
    desc: Online and collaborative editor for the video game "Teeworlds"
  moliverse:
    name: Moliverse
    brief: Molecular rendering in OpenSpace
    desc: Internship in a research lab in Sweden.
  perfanalytics:
    name: PerfAnalytics
    brief: Computer Vision internship
    desc: Climbing holds detection and transformations in 3D space.
  edt:
    name: Polytech EDT
    brief: Timetable application
    desc: Android app for Polytech Paris-Saclay engineering school
  ur:
    name: Royal Game of Ur
    brief: Board game in a web browser
    desc: Features include animations, a bot adversary as well as a web server to play with friends.
  pixels:
    name: Pixels
    brief: Draw together on a pixelated canvas
    desc: Inspired by the viral "r/place" experiment hosted on Reddit.
  podelium:
    name: Podelium [Ongoing]
    brief: Browser Video Game – Tower Defense
    desc: A complex multiplayer, strategy and tower defense game.
  goteka:
    name: Goteka [Experimental]
    brief: Compiling GTK for web browsers
    desc: Porting the GTK toolkit using WebAssembly and a homemade transpiler written in Rust.
