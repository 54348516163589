subtitle: Doctorant en informatique à l'université de Linköping (Suède).
internship: Portfolio de mes projets passés.
contactme: Contactez-moi par mail ou bien directement par téléphone.
curriculum: Retrouvez mon C.V. en version pdf.

navprojects: Projets
navcontact: Contact
navcv: C.V.
titleprojects: Mes Derniers Projets
titlecontact: Contactez-moi
titlecv: Curriculum Vitae

projectlink: "Lien"
projectgit: "Git"
projectcollab: "Collaboration"

projects:
  moncraft:
    name: MonCraft
    brief: Jeu vidéo inspiré de Minecraft
    desc: Jeu vidéo Réalisé "from sratch" en C++ et OpenGL. Jouable en multijoueur, sur PC ou navigateur.
  twwe:
    name: Teeworlds Web Editor
    brief: Éditeur de maps Teeworlds
    desc: Éditeur de maps en ligne et collaboratif pour jeu vidéo "Teeworlds".
  moliverse:
    name: Moliverse
    brief: Rendu de molécules dans OpenSpace
    desc: Stage de recherche à Norrköping en Suède.
  perfanalytics:
    name: PerfAnalytics
    brief: Traitement de vidéos d'escalade
    desc: Stage dans un laboratoire de recherche à Grenoble.
  edt:
    name: Polytech EDT
    brief: Application Emploi du Temps
    desc: Application Android pour l'école Polytech Paris-Saclay.
  ur:
    name: Jeu de Ur
    brief: Jeu de plateau dans le navigateur
    desc: Animations, Implémentation d'un bot et d'un mode multijoueur avec un serveur.
  pixels:
    name: Pixels
    brief: Dessin à plusieurs dans le navigateur
    desc: Inspiré par l'expérience "r/place" de Reddit.
  podelium:
    name: Podelium [En cours]
    brief: Jeu vidéo – Tower Defense
    desc: Jeu vidéo mêlant stratégie, coopération et compétition.
  goteka:
    name: Goteka [Expérimental]
    brief: Transpilation de GTK dans le navigateur
    desc: Ambitieux projet de portage de GTK avec WebAssembly et un transpilateur écrit en Rust.
